<template>
  <u-dashboard-toolbar>
    <template #left>
      <u-breadcrumb
        v-if="event"
        class="px-4 py-2"
        :links="[
          {
            label: 'Events',
            to: '/',
          },
          {
            label: event.name ?? '',
          },
        ]"
      />
    </template>
  </u-dashboard-toolbar>
  <u-dashboard-panel-content v-if="event">
    <div
      v-if="isPublishable"
      class="mb-4"
    >
      <app-banner>
        <p>
          Your event is currently in draft mode. Only your event team can view
          and access your shows. Make sure you
          <u-button
            variant="link"
            class="p-0"
            @click="onPublishEventRequest"
          >
            publish your event
          </u-button>
          so your exhibitors can find and enter into your shows.
        </p>
        <p v-if="!isPaid">
          You will be directed to Stripe to complete your payment.
        </p>
      </app-banner>
    </div>
    <div
      :class="{
        'w-full': true,
        'min-h-[256px]': true,
        'max-h-[256px]': true,
        'grid': true,
        'items-center': true,
        'justify-center': true,
        'rounded-md': true,
        'overflow-hidden': true,
        'shadow-sm': true,
        'border': true,
        'border-gray-300 dark:border-gray-700': true,
        'box-border': true,
      }"
    >
      <nuxt-img
        v-if="typeof event.photo_url === 'string' && event.photo_url.length"
        :src="event.photo_url"
        :alt="`Image for ${event.name}`"
        height="256"
        draggable="false"
        :class="{
          'w-full': true,
          'min-h-[256px]': true,
          'max-h-[256px]': true,
          'object-cover': true,
          'object-center': true,
          'select-none': true,
          'pointer-events-none': true,
        }"
      />
      <picture v-else>
        <source
          type="image/png"
          srcset="/logo.png"
        >
        <nuxt-img
          :class="{
            'w-4/5': true,
            'min-h-[256px]': true,
            'max-h-[256px]': true,
            'mx-auto': true,
            'object-contain': true,
            'object-center': true,
            'select-none': true,
            'pointer-events-none': true,
            'py-2': true,
          }"
          height="256"
          src="/logo.webp"
          alt="The Show Portal"
          draggable="false"
        />
      </picture>
    </div>
    <div class="grid">
      <div class="mt-4 grid gap-2 py-2">
        <div
          :class="{
            'grid': true,
            'grid-cols-[1fr_auto]': isUserEvent,
            'items-center': true,
          }"
        >
          <h3 class="truncate">
            {{ event.metadata.is_draft ? "(Draft) " : "" }} {{ event.name }}
          </h3>
          <u-dropdown
            v-if="isUserEvent"
            :items="items"
            class="h-fit"
            :popper="{ placement: 'bottom-end' }"
          >
            <u-button
              label="Manage"
              color="white"
              trailing-icon="i-ph-caret-down"
            />
          </u-dropdown>
        </div>
        <p class="text-sm font-bold">
          {{ eventShows.length }} show{{
            eventShows.length > 1 ? "s" : ""
          }}
          listed &middot; {{ event.facility }} &middot; {{ event.location }}
        </p>
        <p
          v-if="eventShows.length"
          class="text-sm"
        >
          {{ useTime(event.start_at).format(dateFormat) }} to
          {{ useTime(event.end_at).format(dateFormat) }}
        </p>
        <template v-if="eventShows.length">
          <p
            v-if="event.metadata.is_exhibitor_edit_enabled"
            class="text-sm text-tsppink-500 dark:font-bold dark:text-tsppink-400"
          >
            Entries close at
            {{ useTime(event.registration_end_at).format(dateFormat) }}.
            Purchased entries may be edited in
            <u-button
              variant="link"
              class="p-0 text-sm text-tsppink-500 dark:font-bold dark:text-tsppink-400"
              to="/my/payment/history"
            >
              your account
            </u-button>
            prior to registration close.
          </p>
          <p
            v-else
            class="text-sm text-tsppink-500 dark:font-bold dark:text-tsppink-400"
          >
            Entries close at
            {{ useTime(event.registration_end_at).format(dateFormat) }}.
          </p>
        </template>
        <p
          v-if="
            typeof event.description === 'string' && event.description.length
          "
          class="py-4"
          v-html="useLinkify(event.description)"
        />
      </div>
      <div
        v-if="
          Array.isArray(event.metadata.files) && event.metadata.files.length
        "
      >
        <h5>Documents</h5>
        <div class="mb-4 flex flex-wrap gap-2">
          <nuxt-link
            v-for="file in event.metadata.files"
            :key="file.bucketName"
            :to="file.url"
            external
            target="__blank"
          >
            <u-badge
              color="white"
              size="lg"
            >
              <u-icon
                class="mr-1 shrink-0"
                name="i-ph-file"
              />
              <span class="whitespace-nowrap">{{ file.name }}</span>
            </u-badge>
          </nuxt-link>
        </div>
      </div>
      <div class="pb-8">
        <h5>Lineup</h5>
        <app-banner v-if="eventShows.length === 0">
          <p>There aren't any shows associated with this event.</p>
          <template v-if="isUserEvent">
            <p>Create at least one show to publish the event!</p>
            <u-button
              variant="link"
              class="w-fit p-0"
              :to="`/event/${route.params.id}/show/create`"
            >
              Create a show
            </u-button>
          </template>
        </app-banner>
        <draggable
          v-else-if="isUserEvent"
          v-model="sortedShows"
          group="fields"
          handle=".drag-handle"
          animation="150"
          item-key="name"
          class="flex w-full flex-col gap-4"
          @start="drag = true"
          @end="drag = false"
        >
          <template #item="{ element }">
            <div
              :key="element[0]"
              :class="{
                'grid': true,
                'gap-4': true,
                'p-2': true,
                'border': true,
                'border-solid': true,
                'border-gray-300': true,
                'dark:border-gray-700': true,
                'bg-white': true,
                'dark:bg-gray-900': true,
                'rounded-md': true,
                'select-none': true,
                'cursor-pointer': true,
              }"
            >
              <div class="grid grid-cols-[auto_1fr] items-center gap-2">
                <div
                  class="drag-handle h-fit px-4"
                  :style="{
                    cursor: 'ns-resize',
                  }"
                >
                  <u-icon name="i-ph-dots-six-vertical" />
                </div>
                <app-show
                  :event="event"
                  :show="element"
                  :event-entry-count="eventEntryCount"
                  :is-editable="true"
                  named
                  @duplicate="onShowDuplicate"
                  @delete="onShowDeleteRequest"
                />
              </div>
            </div>
          </template>
        </draggable>
        <u-accordion
          v-else-if="eventShows.length > 3"
          multiple
          :items="eventShows.map((show) => ({ ...show, label: show.name }))"
          :ui="{ wrapper: 'flex flex-col w-full' }"
        >
          <template #default="{ item, index, open }">
            <u-button
              color="gray"
              variant="ghost"
              :class="{
                'border-t': true,
                'border-b': !open && index === eventShows.length - 1,
                'border-gray-200': true,
                'dark:border-gray-700': true,
              }"
              :ui="{ rounded: 'rounded-none', padding: { sm: 'p-3' } }"
            >
              <span class="truncate"> {{ item.label }}</span>
              <template #trailing>
                <u-icon
                  name="i-ph-caret-right"
                  class="ms-auto transition-transform duration-200"
                  :class="[open && 'rotate-90']"
                />
              </template>
            </u-button>
          </template>
          <template #item="{ item }">
            <app-show
              :event="event"
              :show="item"
              :event-entry-count="eventEntryCount"
              :is-editable="false"
            />
          </template>
        </u-accordion>
        <div
          v-else
          class="grid gap-4"
        >
          <app-show
            v-for="item in eventShows"
            :key="item.id"
            :event="event"
            :show="item"
            :event-entry-count="eventEntryCount"
            :is-editable="false"
            bordered
          />
        </div>
      </div>
    </div>
    <div
      v-if="eventShows.length > 0"
      class="flex justify-center gap-4 text-center"
    >
      <template
        v-if="
          isUserEvent
            || ((app.data.time.isAfter(event.registration_start_at)
              || app.data.time.isSame(event.registration_start_at))
              && (app.data.time.isBefore(event.registration_end_at)
                || app.data.time.isSame(event.registration_end_at)))
        "
      >
        <template v-if="auth.user">
          <u-button
            v-if="isUserEvent && event.metadata.is_draft"
            @click="onPublishEventRequest"
          >
            Publish event
          </u-button>
          <u-button
            v-if="event.metadata.is_draft"
            trailing-icon="i-ph-arrow-right"
            color="white"
            :to="`/event/${route.params.id}/enter`"
          >
            Preview draft
          </u-button>
          <u-button
            v-else
            trailing-icon="i-ph-arrow-right"
            :to="`/event/${route.params.id}/enter`"
          >
            Enter event
          </u-button>
        </template>
        <u-button
          v-else
          @click="onSignInToEnter"
        >
          Sign in to enter
        </u-button>
      </template>
      <p v-else>
        Event registration closed.
      </p>
    </div>
  </u-dashboard-panel-content>
  <u-modal v-model="isManageAccessModalActive">
    <div class="grid gap-4 p-4">
      <h5>Event Team</h5>
      <template v-if="isAddingUser">
        <u-form
          :schema="schemaScopedUser"
          :state="scopedUserForm"
          class="space-y-4"
          @submit="addUserToEvent"
        >
          <p>
            Adding a user to your event's team will allow them to publish,
            unpublish, and edit this event, as well as view your event entries.
            Users added here will not have access to your Stripe Connect
            account.
          </p>
          <u-form-group
            label="Registered email"
            name="registered-email"
          >
            <u-input
              v-model="scopedUserForm.email"
              type="email"
            />
          </u-form-group>
          <div class="flex gap-4 pt-8">
            <u-button
              color="white"
              @click="
                () => {
                  isAddingUser = false
                  scopedUserForm.email = ''
                }
              "
            >
              Back
            </u-button>
            <u-button
              type="submit"
              :loading="app.state.isLoading"
              :disabled="app.state.isLoading || !scopedUserEmailValidity"
            >
              Add team member
            </u-button>
          </div>
        </u-form>
      </template>
      <template v-else>
        <div
          v-for="scope in scopedUsers"
          :key="scope.id"
          :class="{
            'flex': true,
            'w-full': true,
            'h-16': true,
            'justify-between': true,
            'items-center': true,
            'rounded-md': true,
            'border': true,
            'border-gray-300 dark:border-gray-700': true,
            'box-border': true,
            'overflow-hidden': true,
          }"
        >
          <div class="w-full pl-4">
            <span>
              <h6 class="font-medium">
                {{ scope.scope.charAt(0).toUpperCase()
                }}{{ scope.scope.slice(1) }}
              </h6>
              {{ scope.scoped_user_name_first }}
              {{ scope.scoped_user_name_last }} ({{ scope.scoped_user_email }})
            </span>
          </div>
          <template v-if="scope.scope !== 'owner'">
            <u-button
              variant="ghost"
              :class="{
                'flex': true,
                'h-full': true,
                'items-center': true,
                'px-4': true,
                'rounded-none': true,
                'appearance-none': true,
                'border-none': true,
                'bg-transparent': true,
                'outline-none': true,
                'transition-colors': true,
                'cursor-pointer': true,
                'enabled:hover:bg-red-500': true,
                'enabled:hover:text-white': true,
              }"
              title="Remove user access"
              :disabled="app.state.isLoading"
              @click="removeUserFromEvent(scope)"
            >
              <u-icon name="i-ph-x" />
            </u-button>
          </template>
        </div>
        <div class="flex gap-4">
          <u-button
            color="white"
            @click="isManageAccessModalActive = false"
          >
            Close
          </u-button>
          <u-button @click="isAddingUser = true">
            Add team member
          </u-button>
        </div>
      </template>
    </div>
  </u-modal>
  <modal-show-delete
    v-if="selectedShow"
    v-model="isDeleteConfirmationModalActive"
    :show="selectedShow"
    :loading="app.state.isLoading"
    @delete="onShowDelete"
  />
  <app-payment-options
    v-if="isPaymentOptionsModalActive"
    :loading="app.state.isLoading"
    @plan-selected="publishEvent"
    @close="isPaymentOptionsModalActive = false"
  />
</template>

<script lang="ts" setup>
import draggable from 'vuedraggable'
import type Stripe from 'stripe'
import { object, string } from 'yup'

useHead({
  title: 'The Show Portal',
})

const route = useRoute()
const supabase = useSupabaseClient<Supabase>()
const app = useApp()
const auth = useAuth()
const toast = useToast()
const data = useData()
const isReturning = ref(false)
const eventId = ref(useShortId(route.params.id))
const { data: shows } = await supabase
  .from('shows')
  .select()
  .eq('event_id', eventId.value)

data.cacheMany('shows', shows)

const event = useGetEvent(eventId, isReturning)
const eventScope = useGetEventScope(eventId, isReturning, false)
const dateFormat = useFancyDateFormat()
const eventShows = computed<PortalShow[]>(() =>
  event.value ? useSortedShows(event.value, data.shows) : [],
)
const sortedShows = ref<PortalShow[]>([])
const eventEntryCount = computedAsync<PortalShowTotals>(
  () => useShowEntries(event.value),
  {
    specieTotals: {},
    showTotals: {},
    showmanshipTotals: {},
    entryFeeTotals: {},
    extraFeeTotals: {},
    extraTotals: {},
  },
)
const isUserEvent = computed(
  () =>
    eventScope.value?.scope === 'editor' || eventScope.value?.scope === 'owner',
)
const isPaid = computedAsync<boolean>(async () => {
  if (!event.value || typeof event.value.metadata.tracking_id !== 'string') {
    return false
  }

  const existing = data.payments.get(event.value.metadata.tracking_id)

  if (existing) {
    return true
  }

  const { data: retrieved } = await supabase
    .from('payments')
    .select('id')
    .eq('metadata->code', 0)
    .eq('id', event.value.metadata.tracking_id)
    .maybeSingle<PortalPayment>()

  if (retrieved) {
    data.payments.set(retrieved.id, retrieved)

    return true
  }

  return false
})
const isPublishable = computed(
  () => event.value?.metadata.is_draft && eventShows.value.length,
)
const showToEnter = useCookie('the-show-portal-show-to-enter')
const scopedUsers = ref<
  (PortalScope & {
    scoped_user_name_first: string
    scoped_user_name_last: string
    scoped_user_email: string
  })[]
>([])
const isAddingUser = ref(false)
const isManageAccessModalActive = ref(false)
const isPaymentOptionsModalActive = ref(false)
const drag = ref(false)
const schemaScopedUser = object({
  email: string()
    .email()
    .required('A valid email is required.')
    .test({
      name: 'cant-be-my-email',
      message: 'You cannot add yourself to your team.',
      test: (value) => value !== auth.user?.email,
    }),
})
const scopedUserForm = reactive({
  email: '',
})
const scopedUserEmailValidity = computed(() =>
  schemaScopedUser.isValidSync(scopedUserForm),
)
const { copy, isSupported } = useClipboard()
const items = computed(() => [
  [
    {
      label: 'View Data',
      icon: 'i-ph-table',
      to: `/event/${route.params.id}/view`,
    },
    {
      label: 'Add Team Member',
      icon: 'i-ph-user-list',
      click: () => {
        isManageAccessModalActive.value = true
      },
    },
  ],
  [
    {
      label: 'Edit',
      icon: 'i-ph-note-pencil',
      to: `/event/${route.params.id}/edit`,
    },
    ...(event.value?.metadata.is_draft === false
      ? [
          {
            label: 'Unpublish',
            icon: 'i-ph-cloud-arrow-down',
            click: unpublishEvent,
          },
          ...(isSupported.value
            ? [
                {
                  label: 'Copy Link',
                  icon: 'i-ph-link',
                  click: copyLink,
                },
              ]
            : []),
        ]
      : isPublishable.value
        ? [
            {
              label: 'Publish',
              icon: 'i-ph-cloud-arrow-up',
              click: onPublishEventRequest,
            },
          ]
        : []),
  ],
  [
    {
      label: 'Add Show',
      icon: 'i-ph-plus',
      to: `/event/${route.params.id}/show/create`,
    },
  ],
])
const isDeleteConfirmationModalActive = ref(false)
const selectedShow = ref<PortalShow | null>(null)

function copyLink() {
  if (!event.value || !isSupported.value) {
    return
  }

  if (event.value.metadata.vanity_url && isSupported.value) {
    copy(
      `${window.location.protocol}//${window.location.host}`
      + `/${event.value.metadata.vanity_url}`,
    )
  }
  else {
    copy(window.location.href)
  }

  toast.add({
    color: 'green',
    title: 'Copied link to clipboard!',
  })
}

function onShowDeleteRequest(show: PortalShow) {
  selectedShow.value = show
  isDeleteConfirmationModalActive.value = true
}

async function getScopes() {
  try {
    const data = await $fetch('/api/v1/scopes', {
      params: {
        resource: route.params.id as string,
      },
    })

    if (data.status_code !== 200) {
      console.error(new Error('Could not get event scopes.'))

      toast.add({
        color: 'red',
        title: 'Could not get event scopes.',
      })
    }

    if (Array.isArray(data.data)) {
      scopedUsers.value = data.data.sort((a, b) => {
        if (a.scope === 'owner') {
          return -1
        }

        if (b.scope === 'owner') {
          return 1
        }

        return 0
      })
    }
  }
  catch (error) {
    console.error(error)
  }
}

async function addUserToEvent() {
  try {
    app.state.isLoading = true

    const data = await $fetch<{
      status_code: number
      message: string
      data: PortalScope & {
        scoped_user_name_first: string
        scoped_user_name_last: string
      }
    }>('/api/v1/scope', {
      method: 'POST',
      body: {
        scoped_user_email: scopedUserForm.email,
        resource_id: route.params.id as string,
        scope: 'editor',
      },
    })

    if (data.status_code !== 200) {
      throw new Error('Could not add user to event.')
    }

    if (typeof data.data !== 'undefined' || data.message) {
      if (data.message === 'Scope request already exists.') {
        toast.add({
          color: 'yellow',
          title: 'That user is already added to your team!',
        })
      }
      else {
        await getScopes()

        toast.add({
          color: 'green',
          title:
            data.data.scoped_user_name_first.length
            && data.data.scoped_user_name_last.length
              /* eslint-disable-next-line max-len */
              ? `${data.data.scoped_user_name_first} ${data.data.scoped_user_name_last} was added to your team!`
              : 'User was added to your team!',
          description:
            data.data.scoped_user_name_first.length
            && data.data.scoped_user_name_last.length
              ? undefined
              : 'Tell them to create an account to get started!',
        })
      }
    }

    isAddingUser.value = false
    scopedUserForm.email = ''
  }
  catch (error) {
    console.error(error)

    toast.add({
      color: 'red',
      title: 'Could not add user to event.',
    })
  }
  finally {
    app.state.isLoading = false
  }
}

async function removeUserFromEvent(scope: PortalScope) {
  try {
    app.state.isLoading = true

    await $fetch('/api/v1/scope', {
      method: 'DELETE',
      body: {
        scoped_user_id: scope.scoped_user_id,
        resource_id: route.params.id as string,
      },
    })

    await getScopes()

    toast.add({
      color: 'green',
      title: 'Team member removed!',
    })
  }
  catch (error) {
    console.error(error)

    toast.add({
      color: 'red',
      title: 'Could not remove user.',
    })
  }
  finally {
    app.state.isLoading = false
  }
}

function getDuplicateName(name: string, existingNames: string[]) {
  const baseNameRegex = /^(.*?)(\s\(\d+\))?$/
  const baseNameMatch = name.match(baseNameRegex)

  if (!baseNameMatch) {
    throw new Error('Invalid name format')
  }

  const baseName = baseNameMatch[1]
  const maxSuffix = existingNames
    .filter((n) => n.startsWith(baseName))
    .map((n) => {
      const match = n.match(/\((\d+)\)$/)
      return match ? Number.parseInt(match[1], 10) : 0
    })
    .reduce((max, num) => Math.max(max, num), 0)

  const newSuffix = maxSuffix + 1
  return `${baseName} (${newSuffix})`
}

async function onShowDuplicate(show: PortalShow) {
  try {
    app.state.isLoading = true

    const { id: _id, ...rest } = show

    const { error } = await supabase
      .from('shows')
      .insert({
        ...rest,
        name: getDuplicateName(
          show.name,
          sortedShows.value.map((s) => s.name),
        ),
        metadata: {
          ...show.metadata,
          order: sortedShows.value.length,
        },
      })
      .select()
      .single()

    if (error) {
      throw error
    }

    toast.add({
      color: 'green',
      title: 'Show duplicated!',
    })
  }
  catch (error) {
    console.error(error)

    toast.add({
      color: 'red',
      title: 'Could not duplicate show.',
    })
  }
  finally {
    app.state.isLoading = false
  }
}

async function onShowDelete() {
  try {
    app.state.isLoading = true

    if (!selectedShow.value) {
      return
    }

    const { error } = await supabase
      .from('shows')
      .delete()
      .eq('id', selectedShow.value.id)

    if (error) {
      throw error
    }

    selectedShow.value = null

    toast.add({
      color: 'green',
      title: 'Show deleted!',
    })
  }
  catch (error) {
    console.error(error)

    toast.add({
      color: 'red',
      title: 'Could not delete show.',
    })
  }
  finally {
    app.state.isLoading = false
  }
}

async function onPublishEventRequest() {
  try {
    app.state.isLoading = true

    if (
      event.value?.metadata.stripe_connect_id
      === auth.user?.stripe_connect_id
      && !auth.isStripeVerified
    ) {
      await navigateTo('/account/stripe')

      toast.add({
        color: 'tspblue',
        title: 'Set up your Stripe Connect account to publish your event.',
      })

      return
    }
    else if (event.value?.metadata.stripe_connect_id) {
      const { data } = await $fetch('/api/v1/stripe/account', {
        params: {
          stripe_connect_id: event.value?.metadata.stripe_connect_id,
        },
      })

      if (!data) {
        toast.add({
          color: 'red',
          title: 'Could not get Stripe Connect account.',
        })

        return
      }

      if (!(data
        && data.details_submitted
        && data.charges_enabled
        && data.payouts_enabled
        && data.capabilities?.transfers === 'active'
        && data.capabilities?.card_payments === 'active')) {
        toast.add({
          color: 'red',
          /* eslint-disable-next-line max-len */
          title: 'Your event\'s Stripe Connect account is not fully setup or is awaiting verification.',
          /* eslint-disable-next-line max-len */
          description: 'Please have the owner of this event set up their Stripe Connect account and try again.',
        })

        return
      }
    }
    else {
      toast.add({
        color: 'red',
        title: 'Please have the owner of this event set up their Stripe Connect account.',
      })

      return
    }

    if (!event.value) {
      throw new Error('Event not found.')
    }

    if (typeof event.value.metadata.is_standard === 'boolean' && isPaid.value) {
      await publishEvent(event.value.metadata.is_standard)
    }
    else {
      isPaymentOptionsModalActive.value = true
    }
  }
  catch (error) {
    console.error(error)
  }
  finally {
    app.state.isLoading = false
  }
}

async function publishEvent(isStandard: boolean) {
  try {
    app.state.isLoading = true

    if (!event.value) {
      throw new Error('Event not found.')
    }

    if (!auth.user) {
      throw new Error('User not signed in.')
    }

    if (isPaid.value) {
      const { error } = await useSupabaseClient<Supabase>()
        .from('events')
        .update({
          metadata: {
            ...event.value.metadata,
            is_draft: false,
            is_standard: isStandard,
          },
        })
        .eq('id', eventId.value)

      if (error) {
        throw error
      }

      toast.add({
        color: 'green',
        title: 'Event published!',
      })
    }
    else {
      const trackingId = useV4()
      const stripeCheckout = await $fetch<{
        status_code: number
        message: string
        data: Stripe.Response<Stripe.Checkout.Session>
        error?: undefined
      }>('/api/v1/stripe/checkout/organizer', {
        method: 'POST',
        body: {
          cancel_url: `${window.location.origin}/event/${route.params.id}/cancel`,
          success_url: `${window.location.origin}/event/${route.params.id}/success`,
          stripe_connect_id: auth.user.stripe_connect_id,
          tracking_id: trackingId,
          is_standard: isStandard,
        },
      })

      if (stripeCheckout.status_code !== 200) {
        throw new Error('Could not create checkout session.')
      }

      const { error } = await useSupabaseClient<Supabase>()
        .from('events')
        .update({
          metadata: {
            ...event.value.metadata,
            tracking_id: trackingId,
            stripe_connect_id: auth.user.stripe_connect_id,
            is_standard: isStandard,
          },
        })
        .eq('id', eventId.value)

      if (error) {
        throw error
      }

      localStorage.setItem('stripe-organizer-checkout-tracking-id', trackingId)

      await navigateTo(stripeCheckout.data.url ?? '/', {
        external: typeof stripeCheckout.data.url === 'string',
      })
    }
  }
  catch (error) {
    console.error(error)

    toast.add({
      color: 'red',
      title: 'Could not publish event.',
    })
  }
  finally {
    app.state.isLoading = false
  }
}

async function unpublishEvent() {
  try {
    app.state.isLoading = true

    if (!event.value) {
      throw new Error('Event not found.')
    }

    const { error } = await supabase
      .from('events')
      .update({
        metadata: {
          ...event.value.metadata,
          is_draft: true,
        },
      })
      .eq('id', event.value.id)

    if (error) {
      throw error
    }

    toast.add({
      color: 'green',
      title: 'Event unpublished!',
      description: 'Your event is now in draft mode.',
    })
  }
  catch (error) {
    console.error(error)

    toast.add({
      color: 'red',
      title: 'Could not unpublish event.',
    })
  }
  finally {
    app.state.isLoading = false
  }
}

async function onSignInToEnter() {
  showToEnter.value = route.params.id as string
  await navigateTo('/sign-in')
}

watch(isManageAccessModalActive, async (value) => {
  if (value) {
    await getScopes()
  }
})

watch(
  event,
  async (value) => {
    if (value) {
      app.data.title = value.name
    }
    else {
      app.data.title = ''
    }
  },
  { immediate: true, deep: true },
)

watch(drag, async (value) => {
  try {
    if (value || !event.value) {
      return
    }

    const shows = sortedShows.value.slice()

    const showsWithStartAt = shows
      .filter((show) => show.start_at)
      .sort((a, b) => useTime(a.start_at).diff(useTime(b.start_at), 'seconds'))

    const sorted: PortalShow[] = []
    let startAtIndex = 0

    for (const show of shows) {
      if (show.start_at) {
        sorted.push(showsWithStartAt[startAtIndex])
        startAtIndex++
      }
      else {
        sorted.push(show)
      }
    }

    sortedShows.value = sorted

    const newSort = sorted.map((show) => show.id)

    if (!useDeepEqual(newSort, event.value.metadata.show_order)) {
      await supabase
        .from('events')
        .update({
          metadata: {
            ...event.value.metadata,
            show_order: newSort,
          },
        })
        .eq('id', event.value.id)
    }
  }
  catch (error) {
    console.error(error)

    toast.add({
      color: 'red',
      title: 'Could not update show order.',
    })

    sortedShows.value = eventShows.value
  }
})

watchOnce(
  eventShows,
  async (value) => {
    try {
      if (event.value) {
        const shows = value.slice()

        const showsWithStartAt = shows
          .filter((show) => show.start_at)
          .sort((a, b) =>
            useTime(a.start_at).diff(useTime(b.start_at), 'seconds'),
          )

        const sorted: PortalShow[] = []
        let startAtIndex = 0

        for (const show of shows) {
          if (show.start_at) {
            sorted.push(showsWithStartAt[startAtIndex])
            startAtIndex++
          }
          else {
            sorted.push(show)
          }
        }

        sortedShows.value = sorted

        const newSort = sorted.map((show) => show.id)

        if (!useDeepEqual(newSort, event.value.metadata.show_order)) {
          await supabase
            .from('events')
            .update({
              metadata: {
                ...event.value.metadata,
                show_order: newSort,
              },
            })
            .eq('id', event.value.id)
        }
      }
    }
    catch (error) {
      console.error(error)

      toast.add({
        color: 'red',
        title: 'Could not update show order.',
      })
    }
    finally {
      sortedShows.value = value.slice()
    }
  },
  { deep: true },
)
</script>
